@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker';

@layer utilities {
  .clip-circle {
    clip-path: circle();
  }
}
