.myModal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.myModalContent {
  padding: 25px;
  background: white;
  border-radius: 24px;
  min-width: 250px;
  width: max-content;
}

@media (max-width: 640px) {
  .myModalContent {
    padding: 5px;
  }
}

.myModal.active {
  display: flex;
  justify-content: center;
  align-items: center;
}